@use 'variables';

.component-content {
    margin: 0 auto 60px auto;
    max-width: 1360px;

    .flex {
        display:flex;
        flex-direction: column;
        margin: 20px 0;
        @include variables.for-tablet-portrait-up {
            flex-direction: row;
        }
        @include variables.for-tablet-landscape-up {
            margin: 60px 0;
        }

        h4 {
            font-size: 2.4rem;
            line-height: 3.6rem;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        .sidebar {
            flex: 0 0 100%;
            padding-right: 3.125rem;
            margin-bottom: 1.25rem;
            @include variables.for-tablet-portrait-up {
                flex: 0 0 250px;
            }
            
            a {
                color: #9D9D9D;
                display: block;
                font-size: 1.25rem;
                line-height: 1.875rem;
                padding: 0.8rem 1.2rem 0.8rem 0;

                &.active {
                    color: #00A053;
                    font-weight: 700;
                }
            }
        }

        .right {
            p {
               font-size: 1rem;
               line-height: 1.688rem;
               margin: 8px 0;
            }

            a {
                color: #00A053;
            }

            ul {
                margin: 0 0 1.4rem 0.875rem;

                li {
                    font-size: 1rem;
                    line-height: 1.688rem;
                    margin: 8px 0;
                }
            }
        }
    }
}
